import {
    FormGroup,
    TextInput,
} from '@genomicsplc/denim-components';
import {
    useEffect,
    useMemo,
    useRef,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    useLocation,
    useNavigate,
    useOutletContext,
} from 'react-router-dom';
import {FetchValidationError} from '../../../fetch-validation-error';
import {AddressNotEligible} from './AddressNotEligible';

export function ShippingAddress() {
    const intl = useIntl();
    const {pathname, state} = useLocation();
    const navigate = useNavigate();
    const {
        currentUserProfile: {shippingAddress} = {},
        errorResponse,
        setPersistFields,
    } = useOutletContext();
    const apartmentSuiteBuildingRef = useRef();
    const cityRef = useRef();
    const recipientRef = useRef();
    const stateRef = useRef();
    const streetAddressRef = useRef();
    const zipCodeRef = useRef();

    useEffect(() => {
        setPersistFields(() => () => ({
            shippingAddress: {
                apartmentSuiteBuilding: apartmentSuiteBuildingRef.current?.value ?? '',
                city: cityRef.current?.value,
                recipient: recipientRef.current?.value,
                state: stateRef.current?.value,
                streetAddress: streetAddressRef.current?.value,
                zipCode: zipCodeRef.current?.value,
            },
        }));

        return () => {
            setPersistFields(null);
        };
    }, []);

    useEffect(() => {
        if (errorResponse?.errorData?.errors?.includes('ADDRESS_STATE_NOT_SUPPORTED')) {
            navigate(pathname, {state: {userIneligible: true}});
        }
    }, [errorResponse]);

    const displayIneligiblePage = useMemo(() => state?.userIneligible ?? false, [state]);
    if (displayIneligiblePage) {
        return <AddressNotEligible />;
    }

    return (
        <>
            <h2 data-test-id={'sign-up-shipping-address-title'}>
                <FormattedMessage id={'signUp.shippingAddress.title'} />
            </h2>
            <FetchValidationError
                errorResponse={streetAddressRef.current?.value ? errorResponse : null}
                pathname={pathname}
            />
            <FormGroup
                dataTestId={'recipient-form-group'}
                label={intl.formatMessage({id: 'signUp.shippingAddress.field.recipient.label'})}
                labelFor={'recipient'}
            >
                <TextInput
                    autoComplete={'name'}
                    dataTestId={'recipient-input'}
                    errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.required'})}}
                    id={'recipient'}
                    name={'recipient'}
                    ref={recipientRef}
                    required={true}
                    value={shippingAddress?.recipient ?? ''}
                />
            </FormGroup>
            <FormGroup
                dataTestId={'street-address-form-group'}
                label={intl.formatMessage({id: 'signUp.shippingAddress.field.street.label'})}
                labelFor={'street-address'}
            >
                <TextInput
                    autoComplete={'address-line1'}
                    dataTestId={'street-address-input'}
                    errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.required'})}}
                    id={'street-address'}
                    name={'streetAddress'}
                    ref={streetAddressRef}
                    required={true}
                    value={shippingAddress?.streetAddress ?? ''}
                />
            </FormGroup>
            <FormGroup
                dataTestId={'apartment-suite-building-form-group'}
                label={intl.formatMessage({id: 'signUp.shippingAddress.field.apartment.label'})}
                labelFor={'apartment-suite-building'}
            >
                <TextInput
                    autoComplete={'address-line2'}
                    dataTestId={'apartment-suite-building-input'}
                    errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.required'})}}
                    id={'apartment-suite-building'}
                    name={'apartmentSuiteBuilding'}
                    ref={apartmentSuiteBuildingRef}
                    value={shippingAddress?.apartmentSuiteBuilding ?? ''}
                />
            </FormGroup>
            <FormGroup
                dataTestId={'city-form-group'}
                label={intl.formatMessage({id: 'signUp.shippingAddress.field.city.label'})}
                labelFor={'city'}
            >
                <TextInput
                    autoComplete={'address-level2'}
                    dataTestId={'city-input'}
                    errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.required'})}}
                    id={'city'}
                    name={'city'}
                    ref={cityRef}
                    required={true}
                    value={shippingAddress?.city ?? ''}
                />
            </FormGroup>
            <div className={'inline-form-groups'}>
                <FormGroup
                    dataTestId={'state-form-group'}
                    label={intl.formatMessage({id: 'signUp.shippingAddress.field.state.label'})}
                    labelFor={'state'}
                >
                    <TextInput
                        autoComplete={'address-level1'}
                        dataTestId={'state-input'}
                        errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.required'})}}
                        id={'state'}
                        name={'state'}
                        ref={stateRef}
                        required={true}
                        value={shippingAddress?.state ?? ''}
                    />
                </FormGroup>
                <FormGroup
                    dataTestId={'zip-code-form-group'}
                    label={intl.formatMessage({id: 'signUp.shippingAddress.field.zipCode.label'})}
                    labelFor={'zip-code'}
                >
                    <TextInput
                        autoComplete={'postal-code'}
                        dataTestId={'zip-code-input'}
                        errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.required'})}}
                        id={'zip-code'}
                        name={'zipCode'}
                        ref={zipCodeRef}
                        required={true}
                        value={shippingAddress?.zipCode ?? ''}
                    />
                </FormGroup>
            </div>
        </>
    );
}
